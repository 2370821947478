import { useCallback, useState } from "react";
import Util from "../../helpers/util";

type AccordionPropType = {
    id: string,
    label: React.ReactElement | string
    initExpanded?: boolean,
    onExpandToggled?: (isExpanded: boolean) => void
} & React.PropsWithChildren

export default function Accordion(props: AccordionPropType) {
    let [isExpanded, setIsExpanded] = useState<boolean>(!!props.initExpanded);

    const toggleIsExpanded = useCallback(() => {
        setIsExpanded((curIsExpanded) => {
            let isExpanded = !curIsExpanded;
            if(props.onExpandToggled)
                props.onExpandToggled!(isExpanded);

            return isExpanded;
        });
    }, [props.onExpandToggled]);

    const accordionId = `${props.id}Accordion`;
    const accordionHeaderId = props.id + "AccordionHeader";
    const accordionContentId = props.id + "AccordionContent";

    return (
        <div className="accordion mb-2" id={accordionId}>
            <div className="accordion-item">
                <h2 className="accordion-header" id={accordionHeaderId}>
                    <button onClick={toggleIsExpanded} className={"accordion-button " + (isExpanded ? "" : "collapsed")} type="button" data-bs-toggle="collapse" data-bs-target={"#" + accordionContentId} aria-expanded={Util.boolToString(isExpanded)} aria-controls={accordionContentId}>
                        {props.label}
                    </button>
                </h2>
                <div id={accordionContentId} className={"accordion-collapse collapse " + (isExpanded ? "show" : "")} aria-labelledby={accordionHeaderId}>
                    <div className="accordion-body">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}