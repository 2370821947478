import React from "react"

type SaveButtonPropType = {
    type?: "button" | "submit"
    disabled?: boolean
    className?: string
    onClick?(event: React.MouseEvent<HTMLButtonElement>): void
} & React.PropsWithChildren

export default function SaveButton(props: SaveButtonPropType) {
    let btnType = props.type;
    let disabled = props.disabled;
    if(!btnType) {
        btnType = "button";
    }
    if(disabled === undefined) {
        disabled = false;
    }

    if(btnType !== "submit" && typeof props.onClick !== "function") {
        throw new Error("SaveButton not of type 'submit' must have onClick defined");
    }

    let className = "btn " + (btnType === "submit" ? "btn-primary" : "btn-secondary");
    className += " mx-auto";
    if(props.className) {
        className += ` ${props.className}`
    }

    function onClick(event: React.MouseEvent<HTMLButtonElement>) {
        if(typeof props.onClick === "function") {
            props.onClick(event);
        }
    }

    return (
        <button type={btnType} className={className} disabled={disabled} onClick={onClick}>Save</button>
    )
}