import Util from "./util"

export type ApiIngredientType = {
    id: string,
    name: string,
    defaultDepartment: string,
    defaultUnit: string
}

export type IngredientType = {
    id: string,
    name: string,
    defaultDepartment: string,
    defaultUnit: string
}

export interface IIngredientSearchResult {
    ingredientId: string,
    recipeId: string,
    name: string,
    defaultDepartment: string,
    defaultUnit: string
}

export default class IngredientHelper {
    static initIngredient(): IngredientType {
        return {
            id: "",
            name: "",
            defaultDepartment: "",
            defaultUnit: ""
        };
    }

    static ingredientsFromApi(apiIngredients: ApiIngredientType[]): IngredientType[] {
        return apiIngredients.map(x => IngredientHelper.ingredientFromApi(x));
    }

    static ingredientFromApi(ingredient: ApiIngredientType): IngredientType {
        return {
            id: ingredient.id,
            name: Util.parseStr(ingredient.name),
            defaultDepartment: Util.parseStr(ingredient.defaultDepartment),
            defaultUnit: Util.parseStr(ingredient.defaultUnit)
        };
    }

    static convertIngredientForApi(ingredient: IngredientType): ApiIngredientType {
        return {
            id: ingredient.id,
            name: ingredient.name,
            defaultDepartment: ingredient.defaultDepartment,
            defaultUnit: ingredient.defaultUnit
        };
    }

    static validateIngredient(ingredient: IngredientType): string[] {
        let errors: string[] = [];
        if(!ingredient.name.trim()) {
            errors.push("Name is required");
        }
        return errors;
    }

    //** IMPORTANT ** If adding ingredients here then also add to UnitOfMeasure.cs
    static getAvailableUoM() {
        return [
            {
                type: "unit",
                values: ["each", "dozen", "gross"]
            },
            {
                type: "volume",
                values: ["tsp", "tbsp", "fl oz", "gill", "c", "pt", "qt", "gal", "ml", "l", "dl"]
            },
            {
                type: "weight",
                values: ["lb", "oz", "mg", "g", "kg"]
            },
            {
                type: "length",
                values: ["mm", "cm", "m", "inch"]
            }
        ];
    }
}