import { useCallback, useEffect, useMemo, useState } from "react";
import Fetch from "../helpers/fetch";

export const FETCH_STATUS = {
    Initializing: "Initializing",
    InProgress: "InProgress",
    Complete: "Complete"
}

//TODO2: Switch anything using 'new Fetch()' to instead use this provider
export default function useFetch() {
	let [status, setFetchStatus] = useState(FETCH_STATUS.Initializing);
	let [fetchCount, setFetchCount] = useState(0);

    useEffect(() => {
        setFetchStatus(fetchCount > 0 ? FETCH_STATUS.InProgress : FETCH_STATUS.Complete);
    }, [fetchCount]);

    const fetchStatus = useMemo(() => {
        return {
            value: status,
            isComplete: () => status === FETCH_STATUS.Complete
        }
    }, [status]);

    const getJson = useCallback((url: string, params?: any): Promise<any> => {
		setFetchCount(prev => prev+1);
        return new Fetch().getJson(url, params).then(result => {
            return result;
        }).finally(() => {
			setFetchCount(prev => prev-1);
        });
    }, []);

	const postJson = useCallback((url: string, jsonData: any = null): Promise<any> => {
		setFetchCount(prev => prev+1);
        return new Fetch().postJson(url, jsonData).then(result => {
            return result;
        }).finally(() => {
			setFetchCount(prev => prev-1);
        });
    }, []);

    const postMPFD = useCallback((url: string, formData: FormData): Promise<any> => {
		setFetchCount(prev => prev+1);
        return new Fetch().postMPFD(url, formData).then(result => {
            return result;
        }).finally(() => {
			setFetchCount(prev => prev-1);
        });
    }, []);

    return { fetchStatus, getJson, postJson, postMPFD };
}