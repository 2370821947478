import React from "react"

type AddButtonPropType = {
    onClick(event: React.MouseEvent<HTMLButtonElement>): void
} & React.PropsWithChildren

export default function AddButton(props: AddButtonPropType) {

    return (
        <button type="button" className="me-1 border-0" onClick={props.onClick}><i className="bi-plus"></i></button>
    )
}