
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import ErrorList from "../utilities/errorList";
import BusyIndicator from "../controls/busyIndicator";
import { ApiErrorInfo } from "../../helpers/errorInfo";


// https://www.filestack.com/fileschool/react/react-file-upload/
export default function GroceryListUploadImage() {
    const { postMPFD, fetchStatus } = useFetch();

    const [file, setFile] = useState<File | null>(null);
    const [errors, setErrors] = useState<string[]>([]);

    let navigate = useNavigate();
    let location = useLocation();

    let navigateToAfter = location.state?.from?.pathname || "/grocery-list/upload";
    
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setFile(event.target.files && event.target.files.length > 0 ? event.target.files[0] : null);
    }

    function onSubmitFileUpload(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setErrors([]);
        if(!file) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        postMPFD("grocerylist/uploadfile", formData).then(() => {
            // success
            navigate(navigateToAfter, {
                state: {
                    from: {
                        pathname: "/grocery-list/upload/new"
                    }
                }
            });
        }, (error) => {
            // fail
            if(error instanceof ApiErrorInfo) {
                setErrors(error.errors);
            }
        });
    }

    function onCancel()
    {
        navigate(navigateToAfter, {
            state: {
                from: {
                    pathname: "/grocery-list/upload/new"
                }
            }
        });
    }

    return (
        <BusyIndicator showIndicator={!fetchStatus.isComplete()}>
            <div className="d-flex flex-grow-1">
                <div className="card shadow-lg mb-5 bg-body rounded flex-grow-1">
                    <div className="card-header">
                        <h3 className="text-center">Upload grocery list image</h3>
                    </div>
                    <ErrorList errors={errors}/>
                    <div className="card-body">
                        <p className="text-warning">Uploading a new image will remove any previous upload that may be in progress.</p>
                        <form onSubmit={onSubmitFileUpload}>
                            <div className="container">
                                <div className="row">
                                    <input type="file" accept="image/*" className="form-control mb-3" onChange={handleChange}/>
                                    <button type="submit" className="btn btn-primary mb-3" disabled={file == null}>Upload</button>
                                </div>
                                <div className="row">
                                    {file ? <img src={URL.createObjectURL(file)} alt="upload" className="h-25 img-thumbnail mx-auto"/> : null}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="card-footer">
                        <button type="button" className="btn btn-secondary float-end" onClick={() => onCancel()}>Cancel</button>
                    </div>
                </div>
            </div>
        </BusyIndicator>
    );
}