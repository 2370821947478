
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import OcrGLItemHelper, { ApiOcrGLItemType, OcrGLItemType } from "../../helpers/ocrGLItemHelper";
import useFetch from "../../hooks/useFetch";
import { ApiErrorInfo } from "../../helpers/errorInfo";
import Util from "../../helpers/util";
import LoadIndicator from "../loadIndicator";
import BusyIndicator from "../controls/busyIndicator";
import SaveButton from "../controls/saveButton";
import AsyncStatusButton, { AsyncStatusButtonType } from "../controls/asyncStatusButton";
import ErrorList from "../utilities/errorList";
import IngredientHelper, { IIngredientSearchResult } from "../../helpers/ingredientHelper";
import RecipeHelper from "../../helpers/recipeHelper";
import AddButton from "../controls/addButton";
import { IngredientAutoCompleteTextbox } from "../controls/ingredientAutoCompleteTextbox";
import IngredientQuantity from "../controls/ingredientQuantity";
import UnitOfMeasureDropdown from "../controls/unitOfMeasureDropdown";
import DeleteButton from "../controls/deleteButton";


function reIndexItems(items: OcrGLItemType[]): void {
    for (let i = 0; i < items.length; i++) {
        items[i].index = String(i);
    }
}

function removeEmptyItems(items: OcrGLItemType[]): void {
    for (let i = 0; i < items.length; i++) {
        if(OcrGLItemHelper.isEmpty(items[i])) {
            items.splice(i, 1);
        }
    }
}

// https://www.filestack.com/fileschool/react/react-file-upload/
export default function GroceryListUpload() {
    const { fetchStatus, getJson, postJson } = useFetch();

    const [ocrGLItems, setOcrGLItems] = useState<OcrGLItemType[]>([]);
    const [originalOcrGLItems, setOriginalOcrGLItems] = useState<OcrGLItemType[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [isFirstLoadComplete, setIsFirstLoadComplete] = useState<boolean>(false);

    let navigate = useNavigate();
    let location = useLocation();

    function onItemsChanged(items: OcrGLItemType[]): void {
        reIndexItems(items);
        setOcrGLItems(items);
    }

    function cleanItemsAndForm(): OcrGLItemType[] {
        let newItems = [...ocrGLItems];
        removeEmptyItems(newItems);
        setOcrGLItems(newItems);
        setErrors([]);
        return newItems;
    }

    function onSave() {
        let newItems = cleanItemsAndForm();
        let apiItems = OcrGLItemHelper.convertItemsToApiJson(newItems);
        
        postJson("grocerylist/saveupload", apiItems).then(result => {
            // Success
            fetchOcrGLItems().then((items) => {
                setOriginalOcrGLItems(items);
                setOcrGLItems(items);
            });
        }).catch(error => {
            if(error instanceof ApiErrorInfo) {
                setErrors(error.errors);
            }
        }).finally(() => {
            
        });
    }

    function AddItemsToGL(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<boolean> {
        let newItems = cleanItemsAndForm();
        let apiItems = OcrGLItemHelper.convertItemsToApiJson(newItems);

        let isSuccess = false;
        return postJson("grocerylist/adduploadtogrocerylist", apiItems).then(result => {
            // Success
            setOcrGLItems([]);
            isSuccess = true;
        }).catch(error => {
            if(error instanceof ApiErrorInfo) {
                setErrors(error.errors);
            }
            isSuccess = false;
        }).then(() => {
            return isSuccess;
        });
    }

    function onAddItemsToGLFinalized(): void {
        navigate("/grocery-list");
    }

    const onNewUpload = useCallback(() => {
        navigate("/grocery-list/upload/new", {
            state: {
                from: {
                    pathname: "/grocery-list/upload"
                }
            }
        });
    }, [navigate]);

    const onCancel = useCallback(() => {
        navigate("/grocery-list");
    }, [navigate]);

    const fetchOcrGLItems = useCallback((): Promise<OcrGLItemType[]> => {
        let items: OcrGLItemType[] = [];
        return getJson("grocerylist/getupload").then((result: { ocrglitems: ApiOcrGLItemType[] }) => {
            result.ocrglitems = result.ocrglitems || []
            items = OcrGLItemHelper.initOcrGLItemsFromApi(result.ocrglitems);
            items.sort((a, b) => (Util.parseInt(a.index) < Util.parseInt(b.index)) ? -1 : 1);
            return items;
        }).catch((error) => {
            console.log(error);
        }).then(() => items);
    }, [getJson]);

    useEffect(() => {
        fetchOcrGLItems().then((items) => {
            if(items.length === 0) {
                if(location.state?.from?.pathname === "/grocery-list/upload/new") {
                    onCancel();
                }
                else {
                    onNewUpload();
                }
            }
            else {
                setOcrGLItems(items);
                setOriginalOcrGLItems(items);
                setIsFirstLoadComplete(true);
            }
        });
    }, [fetchOcrGLItems, location.state?.from?.pathname, onCancel, onNewUpload]);

    if(!fetchStatus.isComplete() && !isFirstLoadComplete) {
        return <LoadIndicator />
    }

    return (
        <BusyIndicator showIndicator={!fetchStatus.isComplete()}>
            <div className="d-flex flex-grow-1">
                <div className="card shadow-lg mb-5 bg-body rounded flex-grow-1">
                    <div className="card-header">
                        <h3 className="text-center">Grocery list upload</h3>
                    </div>
                    <div className="row">
                        <button type="button" className="btn btn-secondary col-3" onClick={onNewUpload}>Upload new image</button> 
                        <SaveButton onClick={onSave} disabled={originalOcrGLItems.length === 0} className="col-3"/>
                        <AsyncStatusButton buttonType={AsyncStatusButtonType.SECONDARY} disabled={ocrGLItems.length === 0} onClick={AddItemsToGL} onFinalized={onAddItemsToGLFinalized} displayClassName="col-3">Add to grocery list</AsyncStatusButton>
                    </div>
                    <ErrorList errors={errors}/>
                    {ocrGLItems.length > 0 ? <OcrGLItemTable items={ocrGLItems} onItemsChanged={onItemsChanged} /> : null}
                    <div className="card-footer">
                        <button type="button" className="btn btn-secondary float-end" onClick={() => onCancel()}>Cancel</button>
                    </div>
                </div>
            </div>
        </BusyIndicator>
    );
}


type OcrGLItemTablePropType = {
    items: OcrGLItemType[],
    onItemsChanged(items: OcrGLItemType[]): void
} & React.PropsWithChildren

function OcrGLItemTable(props: OcrGLItemTablePropType)
{
    const debug = false;
    function onItemChanged(oldItem: OcrGLItemType, newItem: OcrGLItemType): void {
        let newItems = [...props.items];
        let idx = newItems.findIndex(x => x.index === oldItem.index);
        if(idx >= 0)
        {
            newItems[idx] = newItem;
            props.onItemsChanged(newItems);
        }
    }

    function onDeleteItem(item: OcrGLItemType) {
        let newItems = [...props.items];
        let idx = newItems.findIndex(x => x.index === item.index);
        if(idx >= 0)
        {
            newItems.splice(idx, 1);
            props.onItemsChanged(newItems);
        }
    }

    function onAddItem(idx: number) {
        let newItems = [...props.items];
        let newItem = OcrGLItemHelper.initGLItem();
        // add item below current idx
        newItems.splice(idx + 1, 0, newItem);
        props.onItemsChanged(newItems);
    }

    let thClass = debug ? "" : "d-none";
    return (
        <table>
            <thead>
                <tr>
                    {/* Add btn */}
                    <th scope="col" className="col-1"></th>
                    <th className={thClass}>Index</th>
                    <th className={thClass}>Id</th>
                    <th scope="col" className="col-2">Extracted text</th>
                    <th className={thClass}>Ingredient id</th>
                    <th className={thClass}>Ingredient name</th>
                    <th className={thClass}>Recipe id</th>
                    <th className={thClass}>Recipe name</th>
                    <th scope="col" className="col-5">Item for grocery list</th>
                    <th scope="col" className="col-2">Quantity</th>
                    <th scope="col" className="col-2">Unit</th>
                    {/* Delete btn */}
                    <th scope="col" className="col-2"></th>
                </tr>
            </thead>
            <tbody>
                {props.items.map((item, idx) => {
                    return <OcrGLItemRow key={OcrGLItemHelper.getItemKey(item)}
                                        item={item}
                                        onItemChanged={onItemChanged}
                                        onDeleteItem={() => onDeleteItem(item)}
                                        onAddItem={() => onAddItem(idx)}
                                        debug={debug}
                                        /> }
                )}
            </tbody>
        </table>
    )
}

type OcrGLItemRowPropType = {
    item: OcrGLItemType
    onItemChanged(oldItem: OcrGLItemType, newItem: OcrGLItemType): void
    onDeleteItem(): void
    onAddItem(): void
    debug: boolean
} & React.PropsWithChildren

function OcrGLItemRow(props: OcrGLItemRowPropType)
{
    function resetRelatedEntities(item: OcrGLItemType) {
        item.recipeId = "";
        item.recipe = null;
        item.ingredientId = "";
        item.ingredient = null;
    }

    function onQtyChanged(qtyWhole: string, qtyNumerator: string, qtyDenominator: string): void {
        let newItem = {...props.item};
        newItem.quantityWhole = qtyWhole;
        newItem.quantityNumerator = qtyNumerator;
        newItem.quantityDenominator = qtyDenominator;
        props.onItemChanged(props.item, newItem);
    }

    function onUoMChanged(value: string): void {
        let newItem = {...props.item};
        newItem.unit = value;
        props.onItemChanged(props.item, newItem);
    }

    function onTextChanged(value: string): void {
        let newItem = {...props.item};
        resetRelatedEntities(newItem);
        newItem.text = value;
        props.onItemChanged(props.item, newItem);
    }

    function onSelectItem(selectedValue: IIngredientSearchResult): void {
        let newItem = {...props.item};
        resetRelatedEntities(newItem);

        if(selectedValue.recipeId)
        {
            newItem.recipeId = selectedValue.recipeId;
            newItem.recipe = RecipeHelper.initRecipe();
            newItem.recipe.name = selectedValue.name;
            newItem.quantityWhole = "";
            newItem.quantityNumerator = "";
            newItem.quantityDenominator = "";
            newItem.unit = "";
        }
        else if(selectedValue.ingredientId)
        {
            newItem.ingredientId = selectedValue.ingredientId;
            newItem.ingredient = IngredientHelper.initIngredient();
            newItem.ingredient.name = selectedValue.name;
        }
        newItem.text = selectedValue.name;
        props.onItemChanged(props.item, newItem);
    }

    let tdClass = props.debug ? "" : "d-none";
    return (
        <tr>
            <td><AddButton onClick={props.onAddItem}/></td>
            <td className={tdClass}>{props.item.index}</td>
            <td className={tdClass}>{props.item.id}</td>
            <td>{props.item.ocrText}</td>
            <td className={tdClass}>{props.item.ingredientId}</td>
            <td className={tdClass}>{props.item.ingredient?.name}</td>
            <td className={tdClass}>{props.item.recipeId}</td>
            <td className={tdClass}>{props.item.recipe?.name}</td>
            <td>
                <div>
                    <IngredientAutoCompleteTextbox value={props.item.text}
                                                    onTextChanged={onTextChanged}
                                                    includeRecipeAsIngredient={true}
                                                    onSelectItem={onSelectItem}
                                                    />
                    {props.item.recipeId && props.item.text ? <span className="fst-italic fw-lighter fs-sm float-end">(recipe)</span> : null}
                </div>
            </td>
            <td>
                <IngredientQuantity qtyWhole={props.item.quantityWhole}
                                    qtyNumerator={props.item.quantityNumerator}
                                    qtyDenominator={props.item.quantityDenominator}
                                    onQtyWholeChanged={(e) => onQtyChanged(e.target.value, props.item.quantityNumerator, props.item.quantityDenominator)}
                                    onQtyNumeratorChanged={(e) => onQtyChanged(props.item.quantityWhole, e.target.value, props.item.quantityDenominator)}
                                    onQtyDenominatorChanged={(e) => onQtyChanged(props.item.quantityWhole, props.item.quantityNumerator, e.target.value)}
                                    isReadOnly={!!props.item.recipeId}
                                    />
            </td>
            <td>
                <UnitOfMeasureDropdown value={props.item.unit}
                                        onChange={(e) => onUoMChanged(e.currentTarget.value)}
                                        isReadOnly={!!props.item.recipeId}
                                        />
            </td>
            <td><DeleteButton onClick={props.onDeleteItem} /></td>
        </tr>
    )
}