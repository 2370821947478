import { ApiErrorInfo } from "./errorInfo";


export default class Fetch {
    static Jwt = "";
    // NOTE: When using 'proxy' in package.json the paths need to be relative
    BASE_URL = process.env.REACT_APP_API_BASE_URL;

    static OnUnauthorizedResponse: () => void;
    static OnUnexpectedError: () => void;

    getJson(path: string, params: any | null = null): Promise<any> {
        let url = this.BASE_URL + path;
        if(params && Object.keys(params).length > 0) {
            url += "?";
            for(const property in params) {
                let queryParam = "";
                if(Array.isArray(params[property])) {
                    for(var i = 0; i < params[property].length; i++) {
                        var item = params[property][i];
                        queryParam += property + "=" + item;
                        if(i < params[property].length - 1) {
                            queryParam += "&";
                        }
                    }
                }
                else {
                    queryParam += property + "=" + params[property];
                }

                if(queryParam) {
                    url += queryParam + "&";
                }
            }

            // Remove the final '&' that was added when building the querystring
            url = url.substring(0, url.length - 1);
            console.log(url);
        }

        let config = {
            method: "GET"
        };

        return this.doFetch(url, config);
    }

    postForm(path: string, form: HTMLFormElement | undefined): Promise<any> {
        let config = {
            method: "POST",
            body: new FormData(form)
        };

        return this.doFetch(this.BASE_URL + path, config);
    }

    postJson(path: string, jsonData: any = null): Promise<any> {
        let config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(jsonData ? jsonData : "")
        };

        return this.doFetch(this.BASE_URL + path, config);
    }

    postText(path: string, text: any): Promise<any> {
        let config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(text)
        };

        return this.doFetch(this.BASE_URL + path, config);
    }

    // Multi-part form data
    postMPFD(path: string, formData: FormData)
    {
        // ** When using the specific content type the following error showed up: "Failed to read the request form. Missing content-type boundary."
        let config = {
            method: "POST",
            // headers: {
            //     "Content-Type": "multipart/form-data"
            // },
            body: formData
        };

        return this.doFetch(this.BASE_URL + path, config);
    }

    // Will return a promise that will reject if any API call returns !response.ok
    doFetch(url: RequestInfo | URL, config: any): Promise<any> {
        return new Promise((resolve, reject) => {
            config = config ? config : {};
            // This allows sending of cookies with request ('include' will include cookies in same-origin and cross-origin)
            // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#sending_a_request_with_credentials_included
            // https://javascript.plainenglish.io/understanding-the-basics-to-fetch-credentials-863b25968ed5
            // https://portswigger.net/research/exploiting-cors-misconfigurations-for-bitcoins-and-bounties
            // https://portswigger.net/web-security/cors
            // *CORS = A way to relax browser ‘same-origin’ policy.
            config.credentials = "include";

            if(Fetch.Jwt) {
                config.headers = config.headers ? config.headers : {};
                config.headers.Authorization = "Bearer " + Fetch.Jwt;
            }

            setTimeout(() => {
                fetch(url, config).then((response: Response) => {

                    // https://restfulapi.net/http-status-codes/
                    
                    // Check if the response gave us JSON.
                    let isJson = response.headers.get("content-type")?.includes("application/json");
                    let readJson = isJson ? response.json() : Promise.resolve("");
                    
                    readJson.then((json: any) => {
                        if(response.ok) {
                            if(json) {
                                // console.table(json);
                            }
                            resolve(json);
                        }
                        else {
                            let apiErrorInfo = new ApiErrorInfo(json, response);
                            if(apiErrorInfo.isUnauthorized && typeof Fetch.OnUnauthorizedResponse === "function") {
                                Fetch.OnUnauthorizedResponse();
                            }
                            reject(apiErrorInfo);
                        }
                    });
                }).catch((err: any) => {
                    //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
                    console.error(err);
                    if(typeof Fetch.OnUnexpectedError === "function") {
                        Fetch.OnUnexpectedError();
                    }
                    throw err;
                });
            }, Number(process.env.REACT_APP_FETCH_DELAY_MS));
        });
    }
}