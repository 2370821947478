import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './index.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Routing
import App from './App';
import reportWebVitals from './reportWebVitals';
import Recipe from './components/recipe';
import SignUp from './components/account/signUp';
import NotFound from './components/utilities/notFound';
import Home from './views/home';
import SignIn from './components/account/signIn';
import { AuthProvider } from './providers/AuthProvider';
import SignOut from './components/account/signOut';
import { ProtectedRouteProvider } from './providers/ProtectedRouteProvider';
import MyCookbook from './components/myCookbook';
import RecipeAddEdit from './components/recipeAddEdit';
import VerifyEmail from './components/account/verifyEmail';
import Account from './views/account';
import ChangePassword from './components/account/changePassword';
import ResetPassword from './views/resetPassword';
import MealPlans from './views/mealPlans';
import GroceryListView from "./views/groceryListView";
import SupportView from "./views/supportView";
import IngredientListView from "./views/ingredientListView";
import GroceryListUploadImage from "./components/groceryListUpload/groceryListUploadImage";
import GroceryListUpload from "./components/groceryListUpload/groceryListUpload";

// ROUTING
//https://reactrouter.com/docs/en/v6/getting-started/tutorial
//https://stackoverflow.com/questions/37521557/how-to-correctly-use-react-router


const container = document.getElementById('root');
if(container === null) {
    throw Error("missing 'root' element");
}

const root = createRoot(container);
root.render(
    <React.StrictMode>
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App />}>
                        <Route index element={
                            <Home />
                        } />
                        {/* When navigating to directory under /account */}
                        <Route path="account" >
                            <Route index element={
                                <ProtectedRouteProvider>
                                    <Account />
                                </ProtectedRouteProvider>
                            }/>
                            <Route path="sign-up" element={<SignUp />} />
                            <Route path="sign-in" element={<SignIn />} />
                            <Route path="sign-out" element={<SignOut />} />
                            <Route path="verify" element={
                                <ProtectedRouteProvider allowUnverified={true}>
                                    <VerifyEmail />
                                </ProtectedRouteProvider>
                            }/>
                            <Route path="change-password" element={
                                <ProtectedRouteProvider>
                                    <ChangePassword />
                                </ProtectedRouteProvider>
                            } />
                            <Route path="reset-password" element={<ResetPassword />} />
                            {/* Catch all when route isn't defined under /Account/ */}
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route path="about" >
                            <Route index element={
                                <div>About index. Show info/usage/mission statement</div>
                            }/>
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route path="support" >
                            <Route index element={<SupportView />}/>
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route path="cookbook" >
                            <Route index element={
                                <ProtectedRouteProvider>
                                    <MyCookbook />
                                </ProtectedRouteProvider>} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route path="recipe" >
                            <Route index element={<NotFound />} />
                            <Route path="create" element={
                                <ProtectedRouteProvider>
                                    <RecipeAddEdit isNew={true} />
                                </ProtectedRouteProvider>
                            }/>
                            <Route path="edit/:recipeId" element={
                                <ProtectedRouteProvider>
                                    <RecipeAddEdit isNew={false}/>
                                </ProtectedRouteProvider>
                            }/>
                            <Route path=":recipeId" element={<Recipe />} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route path="meal-plan" >
                            <Route index element={
                                <ProtectedRouteProvider>
                                    <MealPlans />
                                </ProtectedRouteProvider>} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route path="grocery-list" >
                            <Route index element={
                                <ProtectedRouteProvider>
                                    <GroceryListView />
                                </ProtectedRouteProvider>} />
                            <Route path="upload">
                                <Route index element={
                                    <ProtectedRouteProvider>
                                        <GroceryListUpload />
                                    </ProtectedRouteProvider>
                                    } />
                                <Route path="new" element={
                                    <ProtectedRouteProvider>
                                        <GroceryListUploadImage />
                                    </ProtectedRouteProvider>
                                    } />
                            </Route>
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route path="ingredient-list" >
                            <Route index element={
                                <ProtectedRouteProvider>
                                    <IngredientListView />
                                </ProtectedRouteProvider>} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        {/* Catch all when path isn't found */}
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
