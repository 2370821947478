import { useCallback, useState } from "react";
import ModalDialog from "../utilities/modalDialog";
import DatePicker from "./datePicker";
import Util from "../../helpers/util";

type ModalDatePickerPropType = {
    initialValue?: Date,
    onClose(): void,
    onOk(selectedDate: Date): void
}

export default function ModalDatePicker(props: ModalDatePickerPropType) {
    const [date, setDate] = useState<string>(() => Util.toIso8601DateString(props.initialValue || new Date()));

    const onOk = useCallback(() => {
        if(date) {
            props.onOk(Util.parseIso8601Date(date)!)
        }
        else {
            props.onClose();
        }
    }, [date, props]);

    const onDateChanged = useCallback((d: string) => {
        if(d) {
            props.onOk(Util.parseIso8601Date(d)!);
        }
        else {
            setDate(d);
        }
    }, [props]);

    return (
        <ModalDialog titleContent="Select a date" onClose={props.onClose} onOk={onOk}>
            <DatePicker value={date} onChange={onDateChanged}/>
        </ModalDialog>
    );
}