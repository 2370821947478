import IngredientHelper, { ApiIngredientType, IngredientType } from "./ingredientHelper"
import { Owner } from "./ownerHelper"
import RecipeHelper, { ApiRecipeIngredientType, ApiRecipeType, RecipeIngredientType, RecipeType } from "./recipeHelper"
import Util from "./util"


export type ApiOcrGLItemType = {
    id: string,
    index: string,
    ocrText: string,
    text: string,
    recipeId: string,
    recipe: ApiRecipeType | null,
    ingredientId: string,
    ingredient: ApiIngredientType | null,
    quantityWhole: string,
    quantityNumerator: string,
    quantityDenominator: string,
    unit: string,
}

export type OcrGLItemType = {
    id: string,
    index: string,
    ocrText: string,
    text: string,
    recipeId: string,
    recipe: RecipeType | null,
    ingredientId: string,
    ingredient: IngredientType | null,
    quantityWhole: string,
    quantityNumerator: string,
    quantityDenominator: string,
    unit: string
}

export default class OcrGLItemHelper {
    static initGLItem(): OcrGLItemType {
        return {
            id: "",
            index: "",
            ocrText: "",
            text: "",
            recipeId: "",
            recipe: null,
            ingredientId: "",
            ingredient: null,
            quantityWhole: "",
            quantityNumerator: "",
            quantityDenominator: "",
            unit: ""
        };
    }

    static initOcrGLItemsFromApi(apiItems: ApiOcrGLItemType[]) : OcrGLItemType[] {
        let items = [];
        for (let apiItem of apiItems)
        {
            items.push(this.initOcrGLItemFromApi(apiItem));
        }

        return items;
    }

    static initOcrGLItemFromApi(apiItem: ApiOcrGLItemType) : OcrGLItemType {
        let text = Util.parseStr(apiItem.text);
        if(apiItem.recipeId && apiItem.recipe && apiItem.recipe.name) {
            text = apiItem.recipe.name;
        }
        else if(apiItem.ingredientId && apiItem.ingredient && apiItem.ingredient.name) {
            text = apiItem.ingredient.name;
        }
        
        return {
            id: apiItem.id,
            index: String(apiItem.index),
            ocrText: apiItem.ocrText,
            text: text,
            recipeId: Util.parseStr(apiItem.recipeId),
            recipe: apiItem.recipe ? RecipeHelper.recipeFromApi(apiItem.recipe) : null,
            ingredientId: Util.parseStr(apiItem.ingredientId),
            ingredient: apiItem.ingredient ? IngredientHelper.ingredientFromApi(apiItem.ingredient) : null,
            quantityWhole: Util.parseStr(apiItem.quantityWhole),
            quantityNumerator: Util.parseStr(apiItem.quantityNumerator),
            quantityDenominator: Util.parseStr(apiItem.quantityDenominator),
            unit: Util.parseStr(apiItem.unit),
        }
    }

    static convertItemsToApiJson(items: OcrGLItemType[]): ApiOcrGLItemType[] {
        let apiItems = [];
        for (let item of items)
        {
            apiItems.push(this.convertItemToApiJson(item));
        }

        return apiItems;
    }

    static convertItemToApiJson(item: OcrGLItemType): ApiOcrGLItemType {
        let apiItem: ApiOcrGLItemType = {
            id: item.id,
            index: item.index,
            ocrText: item.ocrText,
            text: item.text,
            recipeId: item.recipeId,
            recipe: null,
            ingredientId: item.ingredientId,
            ingredient: item.ingredient ? IngredientHelper.convertIngredientForApi(item.ingredient) : null,
            quantityWhole: item.quantityWhole,
            quantityNumerator: item.quantityNumerator,
            quantityDenominator: item.quantityDenominator,
            unit: item.unit,
        }
        return apiItem;
    }

    static getItemKey(item: OcrGLItemType): string
    {
        return `${item.id}_${item.index}_${item.ocrText}`;
    }

    static isEmpty(item: OcrGLItemType): boolean {
        return !item.id && !item.ingredientId && !item.recipeId && !item.text;
    }
}